<!-- 新增积分商品 -->

<template>
	<div>
		<w-dialog ref="addDialog" class="add-dialog" title="添加积分商品" width="45%" btnWidth="140px" top="10vh"
			confirmText="提交保存" @wConfirm="addConfirmBtn">
			<el-form class="add-form" ref="addForm" :model="ruleForm" :rules="rules" labelPosition="top">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="积分" prop="in_exc_integral">
							<el-input v-model="ruleForm.in_exc_integral" type="number" placeholder="请输入兑换所需积分"
								clearable>
							</el-input>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="兑换期间" prop="c_time">
							<el-date-picker v-model="ruleForm.c_time" type="daterange" range-separator="-"
								start-placeholder="开始时间" end-placeholder="结束时间" format="YYYY-MM-DD"
								value-format="YYYY-MM-DD" />
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="奖品" prop="in_type">
							<el-select v-model="ruleForm.in_type" clearable placeholder="请选择奖品" @change="prizeChange">
								<el-option label="优惠券" value="1"></el-option>
								<el-option label="其他" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="12" v-if="ruleForm.in_type == '1'">
						<el-form-item label="优惠券" prop="in_gname">
							<div @click="focusCoupon">
								<el-input v-model="ruleForm.in_gname" placeholder="请选择优惠券" clearable readonly
									:suffix-icon="ArrowDown">
								</el-input>
							</div>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="数量" prop="in_gcount">
							<el-input v-model="ruleForm.in_gcount" type="number" placeholder="请输入奖品数量" clearable>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</w-dialog>

		<!-- 选择优惠券 -->
		<w-dialog ref="couponDialog" class="add-dialog" title="添加积分商品" width="62%" btnWidth="140px" top="10vh"
			:hideFooter="true">
			<common-table ref="roleTable" tableHeight="500px" :ispaging="false" :extraParame="extraParame"
				:apiName="PointsManage.getavailablecoupon" :columns="tableColumns" @onChoice="onChoice">
				<template #f_coutype="{row}">
					<div class="green-tag"><span>{{row.f_coutype}}</span></div>
				</template>
			</common-table>
		</w-dialog>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		computed,
		watch,
		nextTick,
	} from "vue";
	import { ArrowDown, } from '@element-plus/icons-vue';
	import { PointsManage, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";
	import { useStore } from "vuex";

	const props = defineProps({
		tableLoad: {
			type: Function,
			default: null
		}
	})

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})
	const addDialog = ref(null);  // 新增活动弹框对象
	const addForm = ref(null);  // 表单对象
	/** 表单数据对象 */
	const ruleForm = ref({
		in_exc_integral: '',  // 积分
		c_time: [],  // 兑换时间
		in_exc_stime: '',  // 兑换开始时间
		in_exc_etime: '',  // 兑换结束时间
		in_type: '',  // 奖品类型（1：优惠券2：其他）
		in_gid: '',  // 奖品关联的id（奖品为优惠券时，为优惠券id，奖品为其他则为空）
		in_gname: '',  // 奖品名称
		in_gcount: '',  // 奖品数量
	});
	/** 表单规则对象 */
	const rules = reactive({
		in_exc_integral: [{ required: true, message: "请输入兑换所需积分", trigger: "blur", }],
		c_time: [{ required: true, message: "请选择兑换时间", trigger: "blur", }],
		in_type: [{ required: true, message: "请选择奖品类型", trigger: "blur", }],
		in_gcount: [{ required: true, message: "请输入奖品数量", trigger: "blur", }],
	});
	const couponDialog = ref(null);  // 选择优惠券对象
	const roleTable = ref(null);  // 表格对象
	const tableColumns = ref([  // 表格配置数据
		{
			prop: "in_gname",
			label: "优惠券名称",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "f_use_time",
			label: "使用有效期",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "f_coutype",
			label: "优惠券类型",
			type: 'customRender',
			showTooltip: true,
			minWidth: 100
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 100,
			bottons: [
				{
					name: "选择",
					action: "onChoice",
					token: "n_am8mE64sapu9wwVvbF2GWcX6ZgP1",
				},
			],
		},
	]);
	const extraParame = ref({  // 参数
		in_exc_stime: '',
		in_exc_etime: '',
	});
	/**
	 * 
	 * 显示对话框
	 * 
	 * **/
	const openDialog = () => {
		addDialog.value.show();
		ruleForm.value = {
			in_exc_integral: '',  // 积分
			c_time: [],  // 兑换时间
			in_exc_stime: '',  // 兑换开始时间
			in_exc_etime: '',  // 兑换结束时间
			in_type: '',  // 奖品类型（1：优惠券2：其他）
			in_gid: '',  // 奖品关联的id（奖品为优惠券时，为优惠券id，奖品为其他则为空）
			in_gname: '',  // 奖品名称
			in_gcount: '',  // 奖品数量
		};
		nextTick(() => {
			addForm.value.resetFields();
		})
	}
	/**
	 * 
	 * 选择奖品类型
	 * 
	 * */
	const prizeChange = () => {
		// 清空优惠券信息
		ruleForm.value.in_gid = '';
		ruleForm.value.in_gname = '';
	}
	/**
	 * 
	 * 显示优惠券对话框
	 * 
	 * */
	const focusCoupon = () => {
		if (!ruleForm.value.c_time || ruleForm.value.c_time.length == 0) {
			ElMessage.error("请选择兑换期间！");
			return false;
		}
		couponDialog.value.show();
		nextTick(() => {
			extraParame.value.in_exc_stime = ruleForm.value.c_time[0];
			extraParame.value.in_exc_etime = ruleForm.value.c_time[1];
			roleTable.value.tableLoad();
		})
	}
	/**
	 * 
	 * 选择优惠券
	 * 
	 * */
	const onChoice = (row) => {
		ruleForm.value.in_gid = row.in_gid;
		ruleForm.value.in_gname = row.in_gname;
		couponDialog.value.close();
	}
	/**
	 * 
	 * 新增活动确定按钮
	 * 
	 * **/
	const addConfirmBtn = () => {
		addForm.value.validate((valid) => {
			if (valid) {
				ruleForm.value.in_exc_stime = ruleForm.value.c_time[0];
				ruleForm.value.in_exc_etime = ruleForm.value.c_time[1];
				ruleForm.value.in_gname = ruleForm.value.in_type == '2' ? '其他' : ruleForm.value.in_gname;
				addDialog.value.isLoading = true; // 按钮加载中状态
				// console.log("提交", ruleForm.value);
				PointsManage.addIntegralGoods(ruleForm.value).then((res) => {
					addDialog.value.isLoading = false;
					if (res.Code === 200) {
						ElMessage.success('新增成功！'); // 成功提示
						addDialog.value.close(); // 关闭弹框
						props.tableLoad();
					} else {
						ElMessage.error(res.Message);
					}
				}).catch(() => { });
			}
		});
	}
	defineExpose({
		openDialog,
	})
</script>

<style lang="scss">
	.green-tag {
		background-color: #1AC994;
		color: #fff;
		text-align: center;
		border-radius: 5px;
	}

	.el-table__footer-wrapper tbody td.el-table__cell {
		background-color: #fff;
		border-color: #fff;
	}

	.el-table__fixed::before,
	.el-table::before,
	.el-table__fixed-right::before {
		background-color: #fff;
	}

	.el-row {
		border: none;
	}

	.content {
		padding: 20px;
	}

	.add-dialog {
		.el-dialog__body {
			padding: 20px 20px 30px 30px;

			.el-table__row {
				.el-table__cell {
					padding: 9px 0 !important;
				}
			}

		}
	}
</style>