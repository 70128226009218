<!-- 积分管理-积分商城 -->

<template>
	<div class="points-mall main-cnt">
		<div class="content">
			<common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :apiName="PointsManage.getPointsMallLists"
				:columns="tableColumns" @onDetails="onDetails">
				<template #custom>
					<div>积分商城</div>
				</template>

				<template #operate v-if="authData.indexOf('n_xlW1D9nVrJZ6RaViZchqAdxSjINI') != -1">
					<el-button type="primary" round @click="onAddCommodityBtn">
						添加积分商品
					</el-button>
				</template>

				<template #delisting="{row}">
					<template v-if="row.in_status != '2' && authData.indexOf('n_e1GwKBBGOfWk8vDTlEULQj3EzCyA') != -1">
						<el-button @click="onForcedDelisting(row)" class="delisting-btn">强制下架</el-button>
					</template>
				</template>
			</common-table>
		</div>

		<!-- 详情 -->
		<w-dialog ref="refDialog" class="add-dialog" title="兑换详情" width="62%" btnWidth="140px" top="10vh"
			:hideFooter="true">
			<common-table ref="dialogTable" tableHeight="500px" :ispaging="false" :extraParame="extraParame"
				:apiName="PointsManage.getIntegralDetail" :columns="dialogColumns">
			</common-table>
		</w-dialog>

		<!-- 新增积分商品 -->
		<integralProducts ref="refCommodity" :tableLoad="tableLoad"></integralProducts>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch,
		nextTick,
	} from "vue";
	import { ElMessage, ElMessageBox } from "element-plus";
	import { PointsManage, } from "@/plugins/api.js";
	import { useStore } from "vuex";
	import integralProducts from '../components/addIntegralProducts.vue';

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})
	/** 表格对象 */
	const roleTable = ref(null);
	const refCommodity = ref(null);  // 新增积分商品弹框对象
	const refDialog = ref(null);  // 详情对象
	const dialogTable = ref(null);  // 详情表格对象
	const extraParame = ref({
		in_id: '',
	});
	const dialogColumns = ref([  // 表格配置数据
		{
			prop: "in_id",
			label: "商品ID",
			showTooltip: true,
		},
		{
			prop: "m_username",
			label: "用户昵称",
			showTooltip: true,
		},
		{
			prop: "m_mobile",
			label: "手机号",
			showTooltip: true,
		},
		{
			prop: "in_gname",
			label: "奖品",
			showTooltip: true,
		},
		{
			prop: "ine_integral",
			label: "消耗积分",
			showTooltip: true,
		},
		{
			prop: "ine_sn",
			label: "订单编号",
			showTooltip: true,
		},
		{
			prop: "f_ine_ctime",
			label: "兑换时间",
			showTooltip: true,
		},
		{
			prop: "used",
			label: "是否使用",
			showTooltip: true,
		},
	]);

	/** 表格配置数据 */
	const tableColumns = ref([
		{
			prop: "in_id",
			label: "ID",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "in_exc_integral",
			label: "积分",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "in_gname",
			label: "奖品",
			color: "--text-color",
			showTooltip: true,
			minWidth: 150
		},
		{
			prop: "in_gid",
			label: "奖品ID",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "in_gcount",
			label: "奖品数量",
			color: "--text-color",
			showTooltip: true,
		},
		{
			label: "兑换期间",
			prop: "f_in_exc_stime",
			prop2: "f_in_exc_etime",
			type: "connect",
			connectText: "-",
			color: "--text-color",
			showTooltip: true,
			minWidth: "230"
		},
		{
			prop: "redeemed",
			label: "已兑换数量",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "f_in_status",
			label: "状态",
			type: "option",
			metaData: ["进行中", "未开始", "已结束"],
			colorData: ["var(--warning-color)", "var(--text-roseo-color)", "var(--text-gray-color)"],
			showTooltip: true,
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 200,
			bottons: [
				{
					name: "详情",
					action: "onDetails",
					token: "n_am8mE64sapu9wwVvbF2GWcX6ZgP1",
				},
				{
					name: "强制下架",
					action: "delisting",
					type: "customRender",
				},
			],
		},
	]);
	/**
	 * 
	 * 新增活动按钮
	 * 
	 * **/
	const onAddCommodityBtn = () => {
		refCommodity.value.openDialog();
	}
	/**
	 * 
	 * 查看详情
	 * 
	 * */
	const onDetails = (row) => {
		refDialog.value.show();
		extraParame.value.in_id = row.in_id;
		nextTick(() => {
			dialogTable.value.tableLoad();
		})
	}
	/**
	 * 
	 * 新增活动成功重新获取列表数据
	 * 
	 * */
	const tableLoad = () => {
		roleTable.value.tableLoad();
	}
	/**
	 * 
	 * 强制下架按钮
	 * 
	 * */
	const onForcedDelisting = (row) => {
		ElMessageBox.confirm(
			`确定强制下架该商品吗？`,
			'下架确认', {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
		}
		)
			.then(() => {
				PointsManage.forcedDelisting({ in_id: row.in_id }).then((res) => {
					if (res.Code === 200) {
						roleTable.value.tableLoad();
						ElMessage.success(`下架成功!`); // 成功提示
					} else {
						ElMessage.error(res.Message);
					}
				});
			})
			.catch(() => { })
	}

	onMounted(() => {
		roleTable.value.tableLoad();
	});
</script>

<style lang="scss">
	.points-mall {
		font-family: "Source Han Sans CN";

		.flex {
			display: flex;
		}

		.justify-between {
			justify-content: space-between;
		}

		.s-m-b {
			margin-bottom: 10px;
		}

		.top-title {
			height: 32px;
			line-height: 32px;
		}

		.vertical {
			border: 2px solid #1ACB95;
			margin-left: 0;
		}

		.preview-btn {
			width: 60px;
		}

		.el-table__footer-wrapper tbody td.el-table__cell {
			background-color: #fff;
			border-color: #fff;
		}

		.el-table__fixed::before,
		.el-table::before,
		.el-table__fixed-right::before {
			background-color: #fff;
		}

		.el-row {
			border: none;
		}

		.content {
			padding: 20px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 20px 20px 30px 30px;

				.el-table__row {
					.el-table__cell {
						padding: 9px 0 !important;
					}
				}

			}
		}

		.delisting-btn {
			border: none !important;
			color: var(--text-roseo-color) !important;
		}

		.delisting-btn:hover {
			border: none !important;
			color: var(--text-roseo-color) !important;
			background-color: transparent !important;
		}
	}
</style>